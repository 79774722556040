import { ENGAGEMENT_ENTRIES_URI, STATS_URI } from '../../common/endpoints';

import { SOCIAL_MEDIA_TYPE } from '../../../common/constants';
import { get } from '../../common/axiosInstance';

const liveSearchStatsHandlers = function () {
  this.youtube = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(STATS_URI, {
          params: {
            youtube_channel: params.handle.toLowerCase(),
            lang: params.lang,
            days: params.days,
            option: params.option,
            interval: params.interval,
            post_id: params.post_id,
            gender_type: params.gender_type,
            content_type: params.content_type,
            audience_category: params.audience_category,
            type:
              params.type ||
              'topics,hashtags,doi,categories,detailed,influencers,entities,sentiment,posts,followers',
            start_date: String(params?.startDate),
            end_date: String(params?.endDate),
            words_to_match: params.wordsToMatch,
            words_not_to_match: params.wordsNotToMatch,
            data_type: params.data_type,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.facebook = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(STATS_URI, {
          params: {
            facebook_searchname: params.handle,
            lang: params.lang,
            days: params.days,
            interval: params.interval,
            post_id: params.post_id,
            gender_type: params.gender_type,
            type:
              params.type ||
              'topics,hashtags,doi,categories,detailed,influencers,entities,sentiment,posts,followers',
            start_date: String(params?.startDate),
            end_date: String(params?.endDate),
            words_to_match: params.wordsToMatch,
            words_not_to_match: params.wordsNotToMatch,
            data_type: params.data_type,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.twitter = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(STATS_URI, {
          params: {
            twitter_searchname: params?.handle?.toLowerCase(),
            lang: params.lang,
            days: params.days,
            post_id: params.post_id,
            gender_type: params.gender_type,
            content_type: params.content_type,
            audience_category: params.audience_category,
            type:
              params.type ||
              'topics,hashtags,doi,categories,detailed,influencers,entities,sentiment,posts,followers',
            interval: params.interval,
            start_date: String(params?.startDate),
            end_date: String(params?.endDate),
            words_to_match: params.wordsToMatch,
            words_not_to_match: params.wordsNotToMatch,
            data_type: params.data_type,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.reddit = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(STATS_URI, {
          params: {
            Reddit: params.handle.toLowerCase().replace(/\s/g, ''),
            lang: params.lang,
            days: params.days,
            post_id: params.post_id,
            gender_type: params.gender_type,
            content_type: params.content_type,
            audience_category: params.audience_category,
            interval: params.interval,
            type:
              params.type ||
              'topics,hashtags,doi,categories,detailed,influencers,entities,sentiment,posts,followers',
            start_date: String(params?.startDate),
            end_date: String(params?.endDate),
            words_to_match: params.wordsToMatch,
            words_not_to_match: params.wordsNotToMatch,
            data_type: params.data_type,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.instagram = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(STATS_URI, {
          params: {
            instagram_searchname: params.handle.toLowerCase(),
            lang: params.lang,
            days: params.days,
            interval: params.interval,
            post_id: params.post_id,
            gender_type: params.gender_type,
            content_type: params.content_type,
            audience_category: params.audience_category,
            type:
              params.type ||
              'topics,hashtags,doi,categories,detailed,influencers,entities,sentiment,posts,followers',
            start_date: String(params?.startDate),
            end_date: String(params?.endDate),
            ig_type: params.ig_type,
            words_to_match: params.wordsToMatch,
            words_not_to_match: params.wordsNotToMatch,
            data_type: params.data_type,
            instagram_search_for_user_post:
              params.instagram_search_for_user_post,
            instagram_search_for_tag_post: params.instagram_search_for_tag_post,
            instagram_search_for_hashtag_post:
              params.instagram_search_for_hashtag_post,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.articles = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(STATS_URI, {
          params: {
            news_keyword: params.handle,
            lang: params.lang,
            days: params.days,
            post_id: params.post_id,
            gender_type: params.gender_type,
            content_type: params.content_type,
            audience_category: params.audience_category,
            country:
              !params.country || params.country.length === 0
                ? 'all'
                : params.country.join(),
            interval: params.interval,
            type:
              params.type ||
              'topics,hashtags,doi,categories,detailed,influencers,entities,sentiment,posts,followers',
            start_date: String(params?.startDate),
            end_date: String(params?.endDate),
            words_to_match: params.wordsToMatch,
            words_not_to_match: params.wordsNotToMatch,
            data_type: params.data_type,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.tiktok = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(STATS_URI, {
          params: {
            tiktok_searchname: params.handle.toLowerCase(),
            lang: params.lang,
            days: params.days,
            post_id: params.post_id,
            gender_type: params.gender_type,
            content_type: params.content_type,
            audience_category: params.audience_category,
            interval: params.interval,
            type:
              params.type ||
              'topics,hashtags,doi,categories,detailed,influencers,entities,sentiment,posts,followers',
            start_date: String(params?.startDate),
            end_date: String(params?.endDate),
            tiktok_type: params.tiktok_type,
            words_to_match: params.wordsToMatch,
            words_not_to_match: params.wordsNotToMatch,
            data_type: params.data_type,
            tiktok_search_for_user_post: params.tiktok_search_for_user_post,
            tiktok_search_for_keyword_post:
              params.tiktok_search_for_keyword_post,
            tiktok_search_for_hashtag_post:
              params.tiktok_search_for_hashtag_post,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.trustpilot = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(STATS_URI, {
          params: {
            trustPilotcompanyName: params.handle.toLowerCase(),
            lang: params.lang,
            days: params.days,
            post_id: params.post_id,
            interval: params.interval,
            type:
              params.type ||
              'topics,hashtags,doi,categories,detailed,influencers,entities,sentiment,posts,followers',
            start_date: String(params?.startDate),
            end_date: String(params?.endDate),
            words_to_match: params.wordsToMatch,
            words_not_to_match: params.wordsNotToMatch,
            data_type: params.data_type,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.getLiveSearchStatsData = (socialMediaType, params, abortSignal) => {
    switch (socialMediaType) {
      case SOCIAL_MEDIA_TYPE.articles:
        return this.articles(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.instagram:
        return this.instagram(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.reddit:
        return this.reddit(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.facebook:
        return this.facebook(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.twitter:
        return this.twitter(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.youtube:
        return this.youtube(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.tiktok:
        return this.tiktok(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.trustpilot:
        return this.trustpilot(params, abortSignal);
      default:
        return Promise.reject('Illegal Social Media Type');
    }
  };
};
const EngagementEntriesStatsHandlers = function () {
  this.instagram = async function (params, abortSignal) {
    if (params.handle) {
      try {
        const response = await get(ENGAGEMENT_ENTRIES_URI, {
          params: {
            days: params.days,
            handlername: params.handle.toLowerCase(),
            start_date: params.startDate,
            end_date: params.endDate,
          },
          signal: abortSignal,
        });
        return response;
      } catch (error) {
        return error;
      }
    }
  };

  this.getLiveSearchStatsData = (socialMediaType, params, abortSignal) => {
    switch (socialMediaType) {
      case SOCIAL_MEDIA_TYPE.articles:
        return this.articles(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.instagram:
        return this.instagram(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.reddit:
        return this.reddit(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.facebook:
        return this.facebook(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.twitter:
        return this.twitter(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.youtube:
        return this.youtube(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.tiktok:
        return this.tiktok(params, abortSignal);
      case SOCIAL_MEDIA_TYPE.trustpilot:
        return this.trustpilot(params, abortSignal);
      default:
        return Promise.reject('Illegal Social Media Type');
    }
  };
};
export const liveSearchStatsDataHandlers = new liveSearchStatsHandlers()
  .getLiveSearchStatsData;
export const EngagementEntriesHandlers = new EngagementEntriesStatsHandlers()
  .getLiveSearchStatsData;
